@font-face {
  font-family: 'MerryChristmasStar';
  /* src: url("/fonts/Courier.ttf") format("opentype"); */
  src: url('/fonts/MerryChristmasStar.ttf');
}

@font-face {
  font-family: 'typrighter';
  src: url('./font/typrighter/typrighter-B.otf');
}

@font-face {
  font-family: 'typrighterV2';
  src: url('./font/typrighter/typrighterV2-1.otf');
}

@font-face {
  font-family: 'traveler';
  src: url('./font/typrighter/Traveler.ttf');
}

@font-face {
  font-family: 'Nexa';
  /* src: url("/fonts/Courier.ttf") format("opentype"); */
  src: url('/fonts/Nexa.ttf');
}

@font-face {
  font-family: 'Nexa Slab Heavy';
  /* src: url("/fonts/Courier.ttf") format("opentype"); */
  src: url('/fonts/Nexa Slab Heavy.ttf');
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.matchDialog_text {
  transform: rotate(15deg);
  text-align: center;
  font-size: 1.25vw;
  font-family: Courier New;
  color: #ffffff;
  text-shadow: 0px 0px 8px #ffffff;
  position: absolute;
  top: 4.1666vw;
  right: 3.91vw;
}
